<template>
  <b-card>
    <!-- Table Top -->
    <b-row class="list-top-buttons">
      <b-col
        cols="30"
        md="12"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.add-new-item-sidebar
            variant="primary"
            :to="{ name: 'apps-resourcesCategories' }"
          >
            <span class="text-nowrap">Category List</span>
          </b-button>
          <b-button
            v-b-toggle.add-knowledgebase-right
            variant="primary"
            style="margin-left: 10px"
          >
            <span class="text-nowrap">Add Knowledgebases</span>
          </b-button>

          <b-sidebar
            id="add-knowledgebase-right"
            title="Add Knowledgebases"
            bg-variant="white"
            right
            shadow
            backdrop
            style="min-width: 600px !important"
          >
            <div>
              <add-knowledgebase />
            </div>
          </b-sidebar>

          <b-button
            class="ml-2"
            variant="outline-none"
          >
            <feather-icon
              icon="GridIcon"
              size="20"
            />
          </b-button>

          <b-button
            variant="outline-none"
            :to="{ name: 'apps-knowledgebase' }"
          >
            <feather-icon
              icon="MenuIcon"
              size="20"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col
        cols="8"
        md="6"
      >
        <!-- input search -->
        <!-- v-model="searchTerm" -->
        <div class="d-flex align-items-left justify-content-left">
          <b-form-group>
            <div class="d-flex align-items-center">
              <b-form-input
                placeholder="Search"
                type="text"
                class="d-inline-block resource-search"
              />
              <label
                style="margin-top: 5px; margin-left: 20px"
                class="mr-1"
              >Search</label>
            </div>
          </b-form-group>
        </div>
      </b-col>
      <b-col
        cols="2"
        md="6"
      />
    </b-row>

    <b-row class="mt-2">
      <!-- img bottom -->
      <b-col
        v-for="resource in resourcesDisplay"
        :key="resource.id"
        cols="12"
        xs="12"
        md="6"
        lg="4"
        xl="3"
        class="resource-card-column"
      >
        <b-card
          :img-src="resource.image"
          img-bottom
          img-alt="card img"
          :title="resource.title"
          class="mb-2 resource-card"
        >
          <b-row class="topRow">
            <b-col>
              <feather-icon
                icon="FileTextIcon"
                size="20"
              />
            </b-col>
            <b-col>
              <feather-icon
                icon="CircleIcon"
                size="20"
                style="float: right"
              />
            </b-col>
          </b-row>
          <div
            v-if="resource.categories && resource.categories.length > 0"
            class="resourse-categoies-list d-flex justify-content-start flex-wrap"
          >
            <div
              v-for="(cat, index) in resource.categories"
              :key="index"
              class="res-category text-center colors-container text-white width-100 height-20 d-flex align-items-center justify-content-center mr-1 ml-20 shadow"
              :style="'background-color:' + cat.color + ';'"
            >
              <span class=""> {{ cat.name }} </span>
            </div>
          </div>
          <b-card-text class="resource-description">
            {{ resource.description.substr(0, 100) }}...
          </b-card-text>
          <b-card-text>
            <small class="text-muted">Last updated 3 mins ago</small>
          </b-card-text>
        </b-card>
      </b-col>

      <!-- img bottom -->
    </b-row>

    <b-sidebar
      id="edit-knowledgebase-right"
      title="Edit Knowledgebase"
      bg-variant="white"
      right
      shadow
      backdrop
      style="min-width: 600px !important"
    >
      <div>
        <add-resource />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BButton,
  BCardText,
  BRow,
  BCol,
  BCard,
  BSidebar,
  VBToggle,
  BFormInput,
} from "bootstrap-vue";
import AddKnowledgebase from "./AddKnowledgebase.vue";

import { resourcesDisplay } from "@/models/eResources";

export default {
  components: {
    BCard,
    BCardText,
    BButton,
    BRow,
    BCol,
    AddKnowledgebase,
    BSidebar,
    BFormInput,
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      modalShow: false,
      modalShowEdit: false,
      element: {},
      pageLength: 10,
      dir: false,

      // resources: [
      // ],

      rows: [],
      searchTerm: "",
    };
  },
  created() {
    this.$http.get("/good-table/resources").then(res => {
      this.rows = res.data;
    });
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: "en",
        name: "English",
      },
    ];
    /* eslint-disable global-require */

    return {
      locales,
      resourcesDisplay,
    };
  },
};
</script>

<style lang="scss" scoped>
.topRow{margin-top: -75px; margin-bottom: 75px}
.badge {
  font-weight: 400 !important;
}
table.vgt-table td {
  vertical-align: middle;
}
</style>

<style lang="scss">
.card .card-title {
  font-weight: 500;
  font-size: 1.2rem;
  margin-bottom: 0.53rem;
  margin-top: 2.5rem;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.res-category {
  padding: 3px 4px;
  border-radius: 8px;
  margin-top: 15px;
}

.resource-card {
  border: 1px transparent #e2dddd !important;
  border-radius: 15px;
  filter: drop-shadow(rgb(226, 221, 221) 1px 1px 6px);
}

.resource-description {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}

.resource-card .card-body {
  padding: 1rem;
}

/* On screens that are 992px or less, set the padding */
@media screen and (min-width: 1081px) {
  .resource-card-column {
    padding: 5px 35px;
    filter: drop-shadow(rgb(226, 221, 221) 1px 1px 6px);
  }
  .resourse-categoies-list {
    margin-top: -30px;
    margin-bottom: 10px;
  }

  .resource-search {
    margin-left: 20px !important;
  }
}

/* On screens that are 992px or less, set the padding */
@media screen and (max-width: 1080px) {
  .resource-card-column {
    padding: 5px 25px;
    filter: drop-shadow(rgb(226, 221, 221) 1px 1px 6px);
  }

  .resource-search {
    margin-left: 10px !important;
  }

  .resourse-categoies-list {
    margin-top: -30px;
    margin-bottom: 10px;
  }
}

/* On screens that are 600px to 800px, set the padding*/
@media screen and (max-width: 800px) {
  .resource-card-column {
    padding: 5px 15px;
    filter: drop-shadow(rgb(226, 221, 221) 1px 1px 6px);
  }

  .resource-search {
    margin-left: -10px !important;
  }

  .resourse-categoies-list {
    margin-top: -30px;
    height: 0px !important;
    display: none !important;
  }
}

/* On screens that are 600px or less, set the padding*/
</style>
